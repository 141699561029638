import React from 'react'
import MintCountSelector from '../MintCountSelector/MintCountSelector';
import Style from './MintInput.module.css';

function MintInput({
    price,
    maxCount,
    onCountChanged,
    message
}) {
    return (
        <div className={Style.container}>
            <div className={Style.line}/>
            <div className={Style.inputContainer}>
                {
                    message ?
                    <p className={Style.message}>{ message }</p> :
                    <MintCountSelector
                        price={price || 0}
                        maxCount={maxCount || 0}
                        onCountChanged={onCountChanged} />
                }
            </div>
        </div>
    )
}

export default MintInput