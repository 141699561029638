import { ethers } from 'ethers';
import Config from '~/config';
import Allowlist from './Allowlist.json';
import NFT from './NFT';

class Web3Interface {
    constructor() {
        this.injectedProvider = null;
        this.provider = null;
        this.signer = null;
        this.address = null;
        this.isConnected = false;
        this.currentBlockNumber = null;
        this.onStateUpdateCallback = null;
        this.onAccountChangedCallback = null;
        this.isUpdatingTransactions = false;
        this.signature = null;
        this.nft = null;
        this.state = null;
        this.isInNetwork = true;

        if(this.getLastUsedProviderType() !== null)
            this.enable(this.getLastUsedProviderType(), true);

        window.Web3Interface = this;
    }

    getLastUsedProviderType() {
        let providerType = window.localStorage.___W3SP;
        return Number.isNaN(parseInt(providerType)) ? null : parseInt(providerType);
    }

    setLastUsedProviderType(providerType) {
        window.localStorage.___W3SP = providerType || '';
    }

    isMetaMaskAvailable() {
        return typeof window.ethereum !== 'undefined';
    }

    async enable(providerType, isReused) {
        if(providerType === Web3Interface.Providers.WALLET_CONNECT) {
            const provider = new window.WalletConnectProvider.default({
                infuraId: Config.INFURA_ID,
            });
            await provider.enable();
            if(this.getLastUsedProviderType() === providerType && !isReused)
                await provider.wc.killSession()

            this.injectedProvider = provider;
            this.setLastUsedProviderType(providerType);
            await this.connect();
        }
        else {
            if(this.isMetaMaskAvailable()) {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                this.injectedProvider = window.ethereum;
                this.setLastUsedProviderType(providerType);
                await this.connect();
            }
            else {
                this.setLastUsedProviderType(null);
                this.injectedProvider = null;
            }
        }
    }

    async connect() {
        if(!this.injectedProvider)
            return;

        this.provider = new ethers.providers.Web3Provider(this.injectedProvider, "any");
        this.signer = this.provider.getSigner();
        this.instances = [];
        try {
            this.isConnected = true;
            this.address = await this.signer.getAddress();
            this.signature = Allowlist[this.address];
            this.nft = new NFT(this, this.signer, this.signature);

            this.injectedProvider.on('accountsChanged', (account) => {
                if(account.length === 0)
                    this.setLastUsedProviderType(null);

                this.state = null;
                if(this.onStateUpdateCallback)
                    this.onStateUpdateCallback(this.state);
                
                this.connect();
            });

            this.provider.on('network', (network) => {
                this.isInNetwork = network.chainId === Config.Network.ID;
                if(this.onNetworkChangedCallback)
                    this.onNetworkChangedCallback(network.chainId);
            });

            this.provider.on('block', (blockNumber) => {
                this.currentBlockNumber = blockNumber;
                this.updatePendingTransactions(blockNumber);
                this.refreshState();
                if(this.onNewBlock)
                    this.onNewBlock(blockNumber);
            });

            if(this.onAccountChangedCallback)
                this.onAccountChangedCallback(this.address);

            if(this.onNetworkChangedCallback)
                this.onNetworkChangedCallback((await this.provider.getNetwork()).chainId);

            if(this.onPendingTransactionListChangedCallback)
                this.onPendingTransactionListChangedCallback(this.getPendingTransactions());

            this.refreshState();
        }
        catch(ex) {
            this.isConnected = false;
            this.signer = null;
            this.address = null;
            this.signature = null;
            this.state = null;
            if(this.onAccountChangedCallback)
                this.onAccountChangedCallback(null);
        }
    }

    requestNetworkSwitch(networkData) {
        this.injectedProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: networkData.chainId}]
        })
        .catch(error => {
            if(error.code === 4902) {
                this.injectedProvider.request({
                    method: 'wallet_addEthereumChain', 
                    params: [networkData]
                })
            }
        });
    }

    async refreshState() {
        if(this.isRefreshing || !this.isInNetwork)
            return;

        this.isRefreshing = true;

        try {
            this.state = await this.nft.updateState();

            if(this.onStateUpdateCallback)
                this.onStateUpdateCallback(this.state);
        }
        catch(ex) {
            console.log('Web3Interface:refreshState()', ex)
        }
        finally {
            this.isRefreshing = false;
        }
    }

    setOnStateUpdateCallback(callback) {
        this.onStateUpdateCallback = callback;
        if(callback)
            callback(this.state);
    }

    setOnNewBlockCallback(callback) {
        this.onNewBlock = callback;
    }

    setOnAccountChangedCallback(callback) {
        this.onAccountChangedCallback = callback;
        if(callback)
            callback(this.address);
    }

    setOnNetworkChangedCallback(callback) {
        this.onNetworkChangedCallback = callback;
    }

    setOnPendingTransactionListChangedCallback(callback) {
        this.onPendingTransactionListChangedCallback = callback;
    }

    getPendingTransactions() {
        let storageTxList = 
            window.localStorage.___W3TX ?
            JSON.parse(window.localStorage.___W3TX) : [];

        return storageTxList instanceof Array ? storageTxList : [];
    }

    setPendingTransactions(txList) {
        if(!(txList instanceof Array))
            throw new Error('Transaction list must be an array');
        
        window.localStorage.___W3TX = JSON.stringify(txList);
    }

    addPendingTransaction(hash, tag, description, timeout = 300000) {
        let storageTxList = this.getPendingTransactions();
        storageTxList.push({hash, tag, description, sentAt: Date.now(), expiresAt: Date.now() + timeout});
        this.setPendingTransactions(storageTxList);
        
        if(this.onPendingTransactionListChangedCallback)
            this.onPendingTransactionListChangedCallback(storageTxList);
    }

    removePendingTransaction(hash) {
        let storageTxList = this.getPendingTransactions();
        storageTxList = storageTxList.filter(item => item.hash !== hash);
        this.setPendingTransactions(storageTxList);
        
        if(this.onPendingTransactionListChangedCallback)
            this.onPendingTransactionListChangedCallback(storageTxList);
    }

    async updatePendingTransactions(blockNumber) {
        if(this.isUpdatingTransactions)
            return;

        this.isUpdatingTransactions = true;

        try {
            let storageTxList = this.getPendingTransactions();
            let txListUpdated = false;

            for(let txData of storageTxList) {
                let txReceipt = await this.provider.getTransactionReceipt(txData.hash);
                if((txReceipt && txReceipt.confirmations > 0 && txReceipt.blockNumber >= blockNumber) || txData.expiresAt < Date.now()) {
                    storageTxList = storageTxList.filter(item => item.hash !== txData.hash);
                    txListUpdated = true;
                }
            }

            if(txListUpdated) {
                this.setPendingTransactions(storageTxList);
                if(this.onPendingTransactionListChangedCallback)
                    this.onPendingTransactionListChangedCallback(storageTxList);
            }
        }
        catch(ex) {}
        this.isUpdatingTransactions = false;
    }

    async getBalance() {
        return this.provider.getBalance(await this.signer.getAddress())
    }
}

Web3Interface.Providers = {
    UNDEFINED: 0,
    WALLET_CONNECT: 1,
    METAMASK: 2
}

export default new Web3Interface();