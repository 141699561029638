const Config = {
    CONTRACT: '0xc9c8171Aa06F867f99aA28C0427cD7AFC8250f57',
    INFURA_ID: 'f635223ca3c442d09b74422eed6b1c25',
    WALLETCONNECT_HELP: 'https://academy.binance.com/en/articles/how-to-use-walletconnect',
    DEFAULT_SIGNATURE: '0xce12918c6ed68be2bb50c1fa3f9bc906cc505dd1912b0576e0e77a03b466314525df8877ed40f539fa52ea4f56e5014a2b72022b64f82c59df1164f6fabce1c11c',
    SPARSE_UPDATE_RATE_MS: 30000,
    IS_PRESALE: true,
    Network: {
        ID: 1,
        TX_EXPLORER: 'https://etherscan.io/tx/',
        Configuration:{
            chainId: '0x1',
            chainName: 'Ethereum',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18
            },
            rpcUrls: [],
            blockExplorerUrls: [],
        }
    },
    SocialMedia: {
        Twitter: 'https://twitter.com/elitegoatnation',
        Discord: 'https://discord.com/invite/WqfUpY2Ue3',
        Instagram: 'https://instagram.com/elitegoatnation?igshid=YmMyMTA2M2Y=',
        OpenSea: 'https://opensea.io/collection/egn-mint-pass'
    },
    ROADMAP_ENABLED: false,
    RoadmapItems: [
        {
            number: '01',
            title: 'MINT IT LIKE YOU MEAN IT',
            subtitle: 'Join The Ethereum Whitelist',
            content: 'Pre-Mint pass is available 22JUN22. There are 1000 reserved whitelist spots.'
        },
        {
            number: '02',
            title: 'SEIZE THE NANO-SECOND',
            subtitle: 'Reveal Metadata Rarity',
            content: 'Metadata Rarity will be revealed on 13JUL22.'
        },
        {
            number: '03',
            title: '#IYKYK',
            subtitle: 'Attend Invite-Only Events',
            content: 'See the events that you’ve already missed.'
        },
        {
            number: '04',
            title: 'IN FAME WE TRUST',
            subtitle: 'Create your GOAT Profile',
            content: 'This is your Official Elite GOAT Nation website.'
        },
        {
            number: '05',
            title: 'NORMIES WON’T GET IT',
            subtitle: 'Shop the Members-Only Drop.',
            content: 'If you’re not holding you’re not wearing any of this 100% GOAT-certified merch.'
        },
        {
            number: '06',
            title: 'THE NEW SOCIAL ORDER',
            subtitle: 'Whale Watching is in SZN.',
            content: 'Here’s how you get your GOAT on with our official social footprints and Discord.'
        },
        {
            number: '07',
            title: 'GOAT FUEL',
            subtitle: 'Discover the benefits of a FUD-FREE Diet.',
            content: 'Details revealed 13OCT22.'
        },
        {
            number: '08',
            title: 'FORTUNE FAVORS THE GOAT',
            subtitle: 'Scalable Vector Graphics',
            content: 'Next level digital merch and metaverse only stuff to “pimp” your GOAT.'
        },
    ]    
}

export default Config;