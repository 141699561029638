import { ethers } from 'ethers';

class Helpers {

    static joinClasses(...classes) {
        let result = '';
        for(let cls of classes)
            if(cls)
                result += ((result.length === 0) ? '' : ' ') + cls;

        return result;
    }

    static getElapsedTimeString(to, from, endString = ' ago', lessThanMinuteString = 'just now') {
        if (!from)
            from = Date.now();

        let differenceMinutes = Math.floor(
            Math.abs(
                new Date(to).getTime() -
                new Date(from).getTime()
            ) / 60000
        );

        let differenceInUnits = {
            year: Math.floor(differenceMinutes / (60 * 24 * 365)),
            month: Math.floor(differenceMinutes / (60 * 24 * 30)),
            day: Math.floor(differenceMinutes / (60 * 24)),
            hour: Math.floor(differenceMinutes / 60),
            minute: differenceMinutes
        }

        for (let unit in differenceInUnits) {
            let valueForUnit = differenceInUnits[unit];
            let unitString = unit + (valueForUnit > 1 ? 's' : '');

            if (valueForUnit > 0)
                return `${valueForUnit} ${unitString}${endString}`;
        }

        return lessThanMinuteString;
    }

    static stringToHex(value) {
        return value
            .split('')
            .map(character => character.charCodeAt(0).toString(16))
            .join('');
    }

    static getTruncatedAddress(address) {
        if (!address)
            address = '0x0000000000000000000000000000000000000000';

        return address.substr(0, 4) + '...' + address.substr(-4);
    }

    static formatEthPrice(value) {
        let numberValue = parseFloat(value);
        numberValue =
            Number.isNaN(numberValue) || !Number.isFinite(numberValue) ?
                0 : numberValue;

        return numberValue.toFixed(4);
    }

    static formatUsdPrice(value) {
        let numberValue = parseFloat(value);
        numberValue =
            Number.isNaN(numberValue) || !Number.isFinite(numberValue) ?
                0 : numberValue;

        return numberValue.toFixed(2);
    }

    static weiToEth(value, digits) {
        return (
            !value ? 0 :
            !digits ? parseFloat(ethers.utils.formatEther(value)) :
            parseFloat(ethers.utils.formatEther(value)).toFixed(digits)
        )
    }

    static getRequestErrorMessage(response) {
        if (!response || response instanceof String)
            return response;

        const splitResponse = response.split(':');

        if (splitResponse.length > 0 && !splitResponse[0].includes(' '))
            return splitResponse.slice(1, splitResponse.length).join(':')
        else
            return response;
    }

    static getRequestErrorField(response) {
        if (!response || response instanceof String)
            return response;

        const splitResponse = response.split(':');

        if (splitResponse.length > 0 && !splitResponse[0].includes(' '))
            return splitResponse[0];
        else
            return response;
    }

    static isSameAddress(addressA, addressB) {
        return !!(
            addressA && addressB &&
            addressA.toLowerCase() === addressB.toLowerCase()
        )
    }
}

export default Helpers;