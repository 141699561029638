import React from 'react'

function GoatOutline({className}) {
    return (
        <svg
            className={className}
            viewBox="0 0 706 706" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M669.5 624.5C652.167 597.167 613.2 541.2 596 536C574.5 529.5 578.5 539 558.5 524.5C538.5 510 514 501.5 506 500" stroke="url(#bottom-right-gradient)" strokeWidth="7" strokeLinecap="round">
            </path>
            <path d="M231.5 391C226.333 397.5 216.4 417.4 218 445C220 479.5 229 486 218 491C209.2 495 192.667 502.333 185.5 505.5C170.333 496.333 126.9 491.7 74.5 546.5" stroke="url(#bottom-left-gradient)" strokeWidth="7" strokeLinecap="round" />
            <path d="M270 135.719C269.5 131.053 267.3 120.419 262.5 115.219C256.5 108.719 256 102.219 248.5 93.7192C241 85.2192 234 74.7192 222 69.7192C212.986 65.9634 202.5 59.7192 198.5 58.2192C194.833 57.5526 187.3 58.0192 186.5 65.2192C185.7 72.4192 176.833 74.5522 172.5 74.7188C168.833 74.3854 161.2 74.8187 160 79.2188C158.5 84.7188 144 93.2188 137 89.7188" stroke="url(#top-gradient)" strokeWidth="7" strokeLinecap="round" />

            <defs>
                <linearGradient id="top-gradient" gradientTransform="rotate(0)">
                    <stop offset="0%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #D9B76DFF; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>

                    <stop offset="50%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #D9B76DFF; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>

                    <stop offset="100%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #00000000; #D9B76DFF; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>
                </linearGradient>

                <linearGradient id="bottom-right-gradient" gradientTransform="rotate(0)">
                    <stop offset="0%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #00000000; #D9B76DFF; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>

                    <stop offset="50%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #00000000; #00000000; #D9B76DFF; #00000000; #00000000; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>

                    <stop offset="100%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #00000000; #00000000; #00000000; #D9B76DFF; #00000000; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>
                </linearGradient>

                <linearGradient id="bottom-left-gradient" gradientTransform="rotate(0)">
                    <stop offset="0%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #00000000; #00000000; #00000000; #D9B76DFF; #00000000; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>

                    <stop offset="50%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #00000000; #00000000; #00000000; #00000000; #D9B76DFF; #00000000; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>

                    <stop offset="100%" stopColor="#00000000">
                        <animate attributeName="stop-color" values="#00000000; #00000000; #00000000; #00000000; #00000000; #00000000; #00000000; #D9B76DFF; #00000000; #00000000;" dur="7s" repeatCount="indefinite"></animate>
                    </stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GoatOutline