import React from 'react'

function DiscordIcon({ 
	className,
	onClick
}) {
	return (
		<svg
			viewBox="0 0 24 24"
			className={className}
			onClick={onClick}
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_412_785)">
				<path d="M20.317 4.36016C18.7873 3.65829 17.147 3.14117 15.4319 2.84499C15.4007 2.83928 15.3694 2.85356 15.3534 2.88213C15.1424 3.25735 14.9087 3.74686 14.7451 4.1316C12.9004 3.85542 11.0652 3.85542 9.25832 4.1316C9.09466 3.73829 8.85248 3.25735 8.64058 2.88213C8.6245 2.85452 8.59328 2.84023 8.56206 2.84499C6.84792 3.14022 5.20756 3.65734 3.67694 4.36016C3.66369 4.36588 3.65234 4.3754 3.64477 4.38778C0.533392 9.03615 -0.31895 13.5702 0.0991801 18.0481C0.101072 18.07 0.11337 18.091 0.130398 18.1043C2.18321 19.6119 4.17169 20.5271 6.12328 21.1337C6.1545 21.1432 6.18761 21.1318 6.20748 21.1061C6.66912 20.4756 7.08063 19.8109 7.43349 19.1119C7.4543 19.0709 7.43443 19.0224 7.39186 19.0062C6.73913 18.7586 6.11761 18.4567 5.51974 18.1138C5.47244 18.0862 5.46865 18.0186 5.51217 17.9862C5.63799 17.8919 5.7638 17.7939 5.88395 17.6948C5.9057 17.6767 5.93598 17.6729 5.96152 17.6843C9.88929 19.4776 14.1415 19.4776 18.023 17.6843C18.0485 17.672 18.0788 17.6758 18.1015 17.6939C18.2216 17.7929 18.3474 17.8919 18.4742 17.9862C18.5177 18.0186 18.5149 18.0862 18.4676 18.1138C17.8697 18.4634 17.2482 18.7586 16.5945 19.0052C16.5519 19.0214 16.533 19.0709 16.5538 19.1119C16.9143 19.81 17.3258 20.4747 17.7789 21.1051C17.7978 21.1318 17.8319 21.1432 17.8631 21.1337C19.8241 20.5271 21.8126 19.6119 23.8654 18.1043C23.8834 18.091 23.8948 18.071 23.8967 18.0491C24.3971 12.8722 23.0585 8.37523 20.3482 4.38873C20.3416 4.3754 20.3303 4.36588 20.317 4.36016ZM8.02001 15.3216C6.83751 15.3216 5.86313 14.2359 5.86313 12.9026C5.86313 11.5694 6.81859 10.4837 8.02001 10.4837C9.23088 10.4837 10.1958 11.5789 10.1769 12.9026C10.1769 14.2359 9.22142 15.3216 8.02001 15.3216ZM15.9948 15.3216C14.8123 15.3216 13.8379 14.2359 13.8379 12.9026C13.8379 11.5694 14.7933 10.4837 15.9948 10.4837C17.2056 10.4837 18.1705 11.5789 18.1516 12.9026C18.1516 14.2359 17.2056 15.3216 15.9948 15.3216Z" />
			</g>
			<defs>
				<clipPath id="clip0_412_785">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default DiscordIcon