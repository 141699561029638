import React from "react";
import Web3Interface from "./Utilities/Web3Interface";
import { PopupManager } from "./Context/PopupManagerContext";
import Home from "./Views/Home/Home";
import Config from "./config";

function App() {
	const [account, setAccount] = React.useState(null);
	const [nftState, setNftState] = React.useState(null);
	const [isInNetwork, setIsInNetwork] = React.useState(true);
	const [networkSwitchRequested, setNetworkSwitchRequested] = React.useState(false);
	const [transactionList, setTransactionList] = React.useState([]);

	React.useEffect(() => {
		Web3Interface.setOnAccountChangedCallback(setAccount);
		return () => {
			Web3Interface.setOnAccountChangedCallback(null);
		}
	}, [setAccount])

	React.useEffect(() => {
		Web3Interface.setOnStateUpdateCallback(setNftState);
		return () => {
			Web3Interface.setOnStateUpdateCallback(null);
		}
	}, [setNftState])

	React.useEffect(() => {
		Web3Interface.setOnPendingTransactionListChangedCallback(setTransactionList);
		return () => {
			Web3Interface.setOnPendingTransactionListChangedCallback(null);
		}
	}, [setTransactionList])

	React.useEffect(() => {
		Web3Interface.setOnNetworkChangedCallback(networkId => {
			if(networkId !== Config.Network.ID) {
				if(!networkSwitchRequested) {
					Web3Interface.requestNetworkSwitch(Config.Network.Configuration)
					setNetworkSwitchRequested(true);
				}
				setIsInNetwork(false);
			}
			else {
				setIsInNetwork(true);
				setNetworkSwitchRequested(false);
			}
		})
		return () => {
			Web3Interface.setOnNetworkChangedCallback(null);
		}
	}, [])

	return (
		<div className="App">
			<PopupManager>
				<Home 
					account={account}
					isInNetwork={isInNetwork}
					nftState={nftState}
					transactionList={transactionList} />
			</PopupManager>
		</div>
	);
}

export default App;
