import React from 'react'

function PlusIcon({ className }) {
    return (
        <svg 
            className={className}
            viewBox="0 0 32 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="M16 6V26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 16H6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default PlusIcon