import React from 'react';
import Loader from '~/Assets/Vectors/Loader';
import Helpers from '~/helpers';
import Style from './Button.module.css';

function Button({
    className,
    text,
    disabled,
    isLoading,
    onClick
}) {
    return (
        <button 
            className={
                Helpers.joinClasses(
                    disabled || isLoading ?
                    Style.buttonDisabled :
                    Style.button,
                    className
                )
            }
            onClick={onClick} 
            disabled={disabled}>
            { isLoading &&  <Loader className={Style.loader}/> }
            <p 
                className={
                    disabled || isLoading ?
                    Style.textDisabled :
                    Style.text    
                }>
                { text }
            </p>
        </button>
    )
}

export default Button