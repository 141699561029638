import React from 'react';

const Loader = ({
    className,
    onClick,
    color
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 30 30"
            fill="none"
            onClick={onClick}>
            <path fillRule="evenodd" clipRule="evenodd" d="M15 4C8.92487 4 4 8.92487 4 15C4 21.0751 8.92487 26 15 26C21.0751 26 26 21.0751 26 15C26 8.92487 21.0751 4 15 4ZM0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="url(#paint0_linear_174_211)">
                <animateTransform attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from="0 15 15"
                    to="360 15 15"
                    dur="1s"
                    repeatCount="indefinite" />
            </path>
            <defs>
                <linearGradient id="paint0_linear_174_211" x1="15" y1="2" x2="25.3235" y2="17.2941" gradientUnits="userSpaceOnUse">
                    <stop stopColor={color || "#D9B76D"} />
                    <stop offset="1" stopColor={color || "#D9B76D"} stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Loader;