import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import RoadmapMenuItem from '../RoadmapMenuItem/RoadmapMenuItem'
import MenuCloseIcon from '~/Assets/Vectors/MenuCloseIcon'
import Style from './RoadmapMenu.module.css'
import Config from '~/config';

function RoadmapMenu({
    isOpen,
    onClose
}) {
    const [hideBackdrop, setHideBackdrop] = useState(true);
    const backdropVariants = {
        open: { opacity: 1 },
        closed: { opacity: 0 },
    }

    const menuVariants = {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: '-100%' }
    }

    useEffect(
        () => {
            if(isOpen) {
                setHideBackdrop(false);
            }
            else {
                setTimeout(() => {
                    setHideBackdrop(true)
                }, 200);
            }
        }, 
        [isOpen, setHideBackdrop]
    )

    return (
        <motion.div 
            initial={false}
            animate={isOpen ? "open" : "closed"}
            transition={{ duration: 0.3 }}
            className={Style.container}
            style={{x: hideBackdrop ? '-100%' : 0}}
            variants={backdropVariants}
            onClick={onClose}>
            <motion.div 
                initial={false}
                animate={isOpen ? "open" : "closed"}
                transition={{ duration: 0.3 }}
                className={Style.menuContainer}
                variants={menuVariants}
                onClick={(event) => { 
                    event.stopPropagation();
                }}>
            
                <div className={Style.topBar}>
                    <p 
                        className={Style.topBarText} 
                        style={{flex: 1}}>
                        OUR ROADMAP
                    </p>
                    <p className={Style.topBarText}>
                        CLOSE
                    </p>
                    <MenuCloseIcon 
                        className={Style.closeIcon}
                        onClick={onClose}/>
                </div>

                <p className={Style.title}>BRING THE GOAT TO LIFE</p>

                <div className={Style.itemContainer}>
                    {
                        Config.RoadmapItems.map(item => 
                            <RoadmapMenuItem
                                key={item.number}
                                number={item.number}
                                title={item.title}
                                subtitle={item.subtitle}
                                content={item.content}/>
                        )
                    }
                </div>
            </motion.div>
        </motion.div>
    )
}

export default RoadmapMenu