import React from 'react'
import Style from './Header.module.css';
import GoatIcon from '~/Assets/Vectors/GoatIcon'
import DiscordIcon from '~/Assets/Vectors/DiscordIcon';
import TwitterIcon from '~/Assets/Vectors/TwitterIcon';
import MetaMaskIcon from '~/Assets/Vectors/MetaMaskIcon';
import LabeledIcon from '../LabeledIcon/LabeledIcon';
import Helpers from '~/helpers';
import Config from '~/config';
import WalletConnectIcon from '~/Assets/Vectors/WalletConnectIcon';
import InstagramIcon from '~/Assets/Vectors/InstagramIcon';

function Header({
    address,
    isWalletConnect,
    onConnectClicked
}) {
    return (
        <div className={Style.container}>
            <div className={Style.goatIconContainer}>
                <GoatIcon className={Style.goatIcon}/>
            </div>

            {
                Config.SocialMedia.Discord &&
                <DiscordIcon 
                    onClick={() => { 
                        window.open(Config.SocialMedia.Discord, '_blank')
                    }}
                    className={Style.icon}/>
            }

            {
                Config.SocialMedia.Twitter &&
                <TwitterIcon 
                    onClick={() => { 
                        window.open(Config.SocialMedia.Twitter, '_blank')
                    }}
                    className={Style.icon}/>
            }

            {
                Config.SocialMedia.Discord &&
                <InstagramIcon 
                    onClick={() => { 
                        window.open(Config.SocialMedia.Instagram, '_blank')
                    }}
                    className={Style.icon}/>
            }

            {/* <LabeledIcon
                className={Style.labeledIcon}
                iconClassName={Style.metamaskIcon}
                text={
                    address ?
                    Helpers.getTruncatedAddress(address) :
                    'CONNECT WALLET'
                }
                disabled={Config.IS_PRESALE}
                onClick={onConnectClicked}
                icon={
                    isWalletConnect ?
                    WalletConnectIcon :
                    MetaMaskIcon
                } /> */}
        </div>
    )
}

export default Header