import React from 'react'

function MetaMaskIcon({ className }) {
    return (
        <svg 
            viewBox="0 0 26 23" 
            className={className}
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.20752 20.686L10.509 21.562V20.4165L10.7785 20.147H12.665V21.4946V22.4378H10.6438L8.15079 21.3598L7.20752 20.686Z" fill="#D9B76D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.9888 20.686L14.7547 21.562V20.4165L14.4852 20.147H12.5987V21.4946V22.4378H14.62L17.1128 21.3598L17.9888 20.686Z" fill="#D9B76D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7805 18.1938L10.511 20.4172L10.8478 20.1477H14.3514L14.7556 20.4172L14.4861 18.1938L13.9471 17.8569L11.2521 17.9243L10.7805 18.1938Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.9624 3.16669L10.5794 6.93975L11.3206 17.9221H13.9482L14.7567 6.93975L16.239 3.16669H8.9624Z" fill="#D9B76D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.88653 11.4543L0 16.9117L4.71633 16.6422H7.74819V14.2841L7.61344 9.43304L6.93968 9.97205L1.88653 11.4543Z" fill="#D9B76D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.45605 12.0604L10.9809 12.1951L10.3746 15.0249L7.74684 14.3512L5.45605 12.0604Z" fill="#D9B76D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.45605 12.1275L7.74684 14.2835V16.4396L5.45605 12.1275Z" fill="#BD9C53" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.74951 14.3521L10.4446 15.0259L11.3205 17.9231L10.7141 18.2599L7.74951 16.5081V14.3521Z" fill="#BD9C53" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.74653 16.5081L7.20752 20.6854L10.7785 18.1925L7.74653 16.5081Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9819 12.1946L11.3188 17.9217L10.3081 14.9908L10.9819 12.1946Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.65063 16.5755L7.74994 16.5081L7.21093 20.6854L4.65063 16.5755Z" fill="#BD9C53" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.48227 22.5042L7.20919 20.685L4.64889 16.5751L0 16.912L1.48227 22.5042Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.5766 6.94153L7.67953 9.36707L5.45605 12.0621L10.9809 12.2643L10.5766 6.94153Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.20752 20.6854L10.7785 18.1925L10.509 20.3485V21.5613L8.08347 21.0896L7.20752 20.6854Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.9888 20.6854L14.4852 18.1925L14.7547 20.3485V21.5613L17.1803 21.0896L17.9888 20.6854Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.49977 13.4077L10.241 14.9574L7.61324 14.2836L9.49977 13.4077Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.41431 0.000793457L10.5775 6.94047L9.02787 3.16741L1.41431 0.000793457Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.41419 0.000793457L0.201416 3.70648L0.875177 7.74905L0.403544 8.01855L1.07731 8.62494L0.538297 9.09657L1.27943 9.77033L0.807801 10.1746L1.88582 11.5221L6.93903 9.97246C9.40949 7.99609 10.6223 6.98545 10.5773 6.94053C10.5324 6.89562 7.47804 4.58237 1.41419 0.000793457Z" fill="#BD9C53" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.3146 11.4535L25.2012 16.9109L20.4848 16.6414H17.453V14.2833L17.5877 9.43225L18.2615 9.97126L23.3146 11.4535Z" fill="#D9B76D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.7451 12.0596L14.2203 12.1944L14.8266 15.0242L17.4543 14.3504L19.7451 12.0596Z" fill="#D9B76D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.7451 12.1267L17.4543 14.2827V16.4388L19.7451 12.1267Z" fill="#BD9C53" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.4517 14.3514L14.7566 15.0251L13.8807 17.9224L14.4871 18.2592L17.4517 16.5074V14.3514Z" fill="#BD9C53" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.4546 16.5073L17.9937 20.6846L14.4901 18.259L17.4546 16.5073Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.2193 12.1938L13.8824 17.9209L14.8931 14.99L14.2193 12.1938Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.5505 16.5747L17.4512 16.5073L17.9902 20.6846L20.5505 16.5747Z" fill="#BD9C53" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.7189 22.5035L17.992 20.6843L20.5523 16.5744L25.2012 16.9112L23.7189 22.5035Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.6245 6.94073L17.5216 9.36628L19.7451 12.0613L14.2203 12.2635L14.6245 6.94073Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7014 13.4069L14.9602 14.9566L17.5879 14.2828L15.7014 13.4069Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.7869 0L14.6237 6.93968L16.1733 3.16662L23.7869 0Z" fill="#F2D595" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.787 0L24.9998 3.70569L24.326 7.74825L24.7976 8.01776L24.1239 8.62414L24.6629 9.09578L23.9217 9.76954L24.3934 10.1738L23.3154 11.5213L18.2621 9.97167C15.7917 7.9953 14.5789 6.98466 14.6238 6.93974C14.6688 6.89482 17.7231 4.58158 23.787 0Z" fill="#BD9C53" />
        </svg>
    )
}

export default MetaMaskIcon