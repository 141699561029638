import React from 'react';
import Button from '../Button/Button';
import OpenSeaIcon from '~/Assets/Vectors/OpenSeaIcon';
import RoadmapIcon from '~/Assets/Vectors/RoadmapIcon';
import IconButton from '../IconButton/IconButton';
import LabeledIcon from '../LabeledIcon/LabeledIcon';
import Config from '~/config';
import Style from './TopFooter.module.css';

function TopFooter({
    onMintClicked,
    isMinting,
    mintingDisabled,
    onRoadmapClicked
}) {
    return (
        <div className={Style.container}>
            <div className={Style.splitter + ' ' + Style.splitterStart}>
                <LabeledIcon
                    className={Style.labeledIcon}
                    text="OUR ROADMAP"
                    disabled={Config.IS_PRESALE || !Config.ROADMAP_ENABLED}
                    onClick={onRoadmapClicked} />

                <IconButton
                    className={Style.iconButton}
                    iconClassName={Style.roadmapIcon}
                    icon={RoadmapIcon}
                    disabled={Config.IS_PRESALE || !Config.ROADMAP_ENABLED}
                    onClick={onRoadmapClicked}/>
            </div>

            <div className={Style.splitter + ' ' + Style.splitterCenter}>
                <Button 
                    text={
                        isMinting ?
                        "MINTING..." :
                        "START MINTING"
                    }
                    onClick={ onMintClicked }
                    isLoading={ isMinting }
                    disabled={ mintingDisabled || Config.IS_PRESALE } />
            </div>

            <div className={Style.splitter + ' ' + Style.splitterEnd}>
                <LabeledIcon
                    className={Style.labeledIcon}
                    text="VIEW ON OPENSEA"
                    icon={OpenSeaIcon}
                    disabled={Config.IS_PRESALE}
                    onClick={() => {
                        window.open(Config.SocialMedia.OpenSea, "_blank");
                    }} />

                <IconButton
                    className={Style.iconButton}
                    iconClassName={Style.openseaIcon}
                    icon={OpenSeaIcon}
                    disabled={Config.IS_PRESALE}
                    onClick={() => {
                        window.open(Config.SocialMedia.OpenSea, "_blank");
                    }}/>
            </div>
        </div>
    )
}

export default TopFooter