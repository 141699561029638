import React from 'react'

function RoadmapIcon({className}) {
    return (
        <svg
            className={className}
            viewBox="0 0 20 21" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="M4.64258 6.42857L8.81871 2.25243C10.0789 0.99224 12.1074 0.94616 13.4235 2.14783V2.14783C14.827 3.42925 14.8768 5.62289 13.533 6.96672L6.78543 13.7143C5.60197 14.8978 5.60197 16.8165 6.78544 18V18C7.9689 19.1835 9.88768 19.1835 11.0711 18L15.1426 13.9286" stroke="#D9B76D" strokeWidth="1.5" />
            <circle cx="16.6433" cy="12.2143" r="2.35714" stroke="#D9B76D" strokeWidth="1.5" />
            <circle cx="3.35714" cy="8.35714" r="2.35714" stroke="#D9B76D" strokeWidth="1.5" />
        </svg>
    )
}

export default RoadmapIcon