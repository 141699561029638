import React from 'react'

function TwitterIcon({ 
    className,
    onClick
}) {
    return (
        <svg
            viewBox="0 0 28 28"
            onClick={onClick}
            className={className}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80852 25.375C19.372 25.375 25.1513 16.6212 25.1513 9.03217C25.1513 8.78608 25.1459 8.53451 25.1349 8.28842C26.2592 7.47537 27.2294 6.4683 28 5.31451C26.9529 5.78037 25.8412 6.08461 24.7029 6.21686C25.9015 5.49839 26.799 4.36972 27.2289 3.04006C26.1013 3.70832 24.8682 4.17971 23.5823 4.43405C22.716 3.51349 21.5705 2.90397 20.323 2.69973C19.0754 2.49548 17.7954 2.70789 16.6807 3.30411C15.566 3.90033 14.6788 4.84716 14.1562 5.9982C13.6335 7.14924 13.5047 8.44038 13.7895 9.67201C11.5062 9.55744 9.27259 8.96432 7.23331 7.93111C5.19403 6.89791 3.39464 5.44768 1.9518 3.67444C1.21847 4.93878 0.994066 6.43492 1.3242 7.85877C1.65434 9.28263 2.51424 10.5274 3.72914 11.34C2.81707 11.311 1.92497 11.0655 1.12656 10.6236V10.6947C1.12575 12.0215 1.58445 13.3077 2.42469 14.3346C3.26494 15.3614 4.43488 16.0657 5.73562 16.3275C4.89073 16.5587 4.00398 16.5923 3.14398 16.4259C3.51103 17.567 4.22517 18.565 5.18673 19.2807C6.1483 19.9964 7.30931 20.394 8.50773 20.4181C6.47316 22.0163 3.95987 22.8831 1.37266 22.879C0.913835 22.8783 0.455466 22.8502 0 22.7948C2.62833 24.481 5.68579 25.3766 8.80852 25.375Z" />
        </svg>
    )
}

export default TwitterIcon