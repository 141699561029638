import React, { useState } from 'react';
import { usePopupManager } from '~/Context/PopupManagerContext';
import Style from "./Home.module.css";
import Images from '~/Assets/Images';
import Header from '~/Components/Header/Header';
import MintInput from '~/Components/MintInput/MintInput';
import TopFooter from '~/Components/TopFooter/TopFooter';
import GoatOutline from '~/Assets/Vectors/GoatOutline';
import RoadmapMenu from '~/Components/RoadmapMenu/RoadmapMenu';
import Web3Interface from '~/Utilities/Web3Interface';
import Helpers from '~/helpers';
import Config from '~/config';
import MessagePopup from '~/Popups/MessagePopup';
import TransactionSentPopup from '~/Popups/TransactionSentPopup';
import ConnectWalletPopup from '~/Popups/ConnectWalletPopup';

function Home({
    account,
    isInNetwork,
    nftState,
    transactionList
}) {
    const { showPopup } = usePopupManager();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMinting, setIsMinting] = useState(false);
    const [mintCount, setMintCount] = useState(1);

    const handleMintClicked = async () => {
        let currentBalance = await Web3Interface.getBalance();
        let mintCost = nftState.price.mul(mintCount)

        if (currentBalance.gte(mintCost)) {
            setIsMinting(true)
            Web3Interface.nft
                .mint(mintCount)
                .then((tx) => {
                    Web3Interface.addPendingTransaction(
                        tx.hash,
                        "MINT PASS",
                        "Minting tokens..."
                    )
                    showPopup(TransactionSentPopup, { hash: tx.hash })
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsMinting(false)
                })
        }
        else {
            showPopup(
                MessagePopup,
                {
                    title: 'Insufficient funds',
                    message: `Not enough funds to send transaction. Your current balance is ${Helpers.weiToEth(currentBalance, 3)} ETH, but the amount required for the mint is ${Helpers.weiToEth(mintCost, 3)} ETH. Please fund your wallet and try again!`
                }
            )
        }
    }

    const handleOpenMenu = () => {
        if(!Config.IS_PRESALE && Config.ROADMAP_ENABLED)
            setIsMenuOpen(true);
    }

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    }

    return (
        <>
            <RoadmapMenu
                isOpen={isMenuOpen}
                onClose={handleCloseMenu} />

            <div className={Style.topContainer}>
                <div className={Style.centerContentContainer}>
                    <div className={Style.goatImageContainer}>
                        <img
                            className={Style.goatImage}
                            alt=""
                            src={Images.Goat} />
                    </div>

                    <div className={Style.goatImageContainer}>
                        <GoatOutline className={Style.goatImage} />
                    </div>

                    <div className={Style.textContainer}>
                        <p className={Style.title}>HERE FOR THE GREATNESS</p>
                        <p className={Style.description}>Elite GOAT Nation creates experiences that are 100% GOAT-CERTIFIED for people that are here for the greatness.</p>
                        {/* {
                            nftState ?
                            <p className={Style.minted}> <strong>{ nftState.maxSupply - nftState.totalSupply }</strong> of <strong>{ nftState.maxSupply - 1 }</strong> passes left</p> :
                            <p className={Style.minted}>Loading pass stats...</p>
                        } */}
                    </div>
                </div>
                
                <div className={Style.centerContentContainerMobile}>
                    <div className={Style.textContainerMobile}>
                        <p className={Style.title}>HERE FOR THE GREATNESS</p>
                        <p className={Style.description}>Elite GOAT Nation creates experiences that are 100% GOAT-CERTIFIED for people that are here for the greatness.</p>
                        {/* {
                            nftState ?
                            <p className={Style.minted}> <strong>{ nftState.maxSupply - nftState.totalSupply }</strong> of <strong>{ nftState.maxSupply - 1 }</strong> passes left</p> :
                            <p className={Style.minted}>Loading pass stats...</p>
                        } */}
                    </div>

                    <div className={Style.goatImageContainerMobile}>
                        <img
                            className={Style.goatImageMobile}
                            alt=""
                            src={Images.Goat} />

                        <div className={Style.goatImageInnerContainerMobile}>
                            <GoatOutline className={Style.goatImageMobile} />
                        </div>
                    </div>
                </div>
                <div className={Style.gradientCover}>
                    <Header
                        address={account}
                        isWalletConnect={Web3Interface.getLastUsedProviderType() === 1}
                        onConnectClicked={() => {
                            showPopup(ConnectWalletPopup, {})
                        }} />

                    <div style={{ flex: 1 }} />

                    <MintInput
                        message={
                            Config.IS_PRESALE ? 'Sale not open yet' :
                            !account ? 'Wallet not connected' :
                            !isInNetwork ? `Switch to ${Config.Network.Configuration.chainName} network` :
                            !nftState ? 'Fetching data...' :
                            !nftState.isActive ? 'The sale has ended' :
                            !nftState.isPublicSale && !nftState.isValidPermit ? 'Address not whitelisted' :
                            !nftState.isPublicSale && nftState.availableMints === 0 ? 'Address already minted all tokens' :
                            transactionList.length > 0 ? transactionList[0].description :
                            null
                        }
                        price={Helpers.weiToEth(nftState?.price)}
                        onCountChanged={setMintCount}
                        maxCount={
                            nftState?.availableMints
                        }
                    />

                    <TopFooter
                        mintingDisabled={
                            !account || !nftState || !nftState.isActive ||
                            !isInNetwork ||
                            (!nftState.isPublicSale && !nftState.isValidPermit) ||
                            (!nftState.isPublicSale && nftState.availableMints === 0) ||
                            (transactionList.length > 0)
                        }
                        isMinting={isMinting}
                        onMintClicked={handleMintClicked}
                        onRoadmapClicked={handleOpenMenu} />
                </div>
            </div>
        </>
    )
}

export default Home;