import React from 'react';
import PopupContainer from '../PopupContainer';
import MenuCloseIcon from '~/Assets/Vectors/MenuCloseIcon';
import Config from '~/config';
import Style from './ConnectWalletPopup.module.css';
import WalletButton from './WalletButton';
import MetaMaskIcon from '~/Assets/Vectors/MetaMaskIcon';
import WalletConnectIcon from '~/Assets/Vectors/WalletConnectIcon';
import Web3Interface from '~/Utilities/Web3Interface';

const ConnectWalletPopup = ({
    onClose,
    isOpen,
}) => {
    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={onClose}
            backdropColor="#0009">

            <div className={Style.container}>

                <div className={Style.closeContainer}>     
                    <MenuCloseIcon
                        className={Style.closeIcon}
                        onClick={onClose}/>
                </div>

                <p className={Style.title}>Connect your Wallet</p>
                <p className={Style.message}>Please select your preferred wallet below! If MetaMask is not available, you can use WalletConnect through your Mobile Wallet App.</p>

                <div className={Style.walletButtonList}>
                    <WalletButton
                        name="MetaMask"
                        disabled={!Web3Interface.isMetaMaskAvailable()}
                        icon={MetaMaskIcon} 
                        onClick={() => {
                            Web3Interface.enable(2)
                            if(onClose)
                                onClose()
                        }}/>

                    <WalletButton
                        name="WalletConnect"
                        icon={WalletConnectIcon}
                        onClick={() => {
                            Web3Interface.enable(1)
                            if(onClose)
                                onClose()
                        }} />
                </div>

                <div 
                    className={Style.howToLink}
                    onClick={() => {
                        window.open(Config.WALLETCONNECT_HELP, '_blank')
                    }}>
                    How to Use WalletConnect
                </div>
            </div>

        </PopupContainer>
    );
}

export default ConnectWalletPopup;