import React from 'react'
import Helpers from '~/helpers';
import Style from './IconButton.module.css';

function IconButton({
    className,
    iconClassName,
    icon,
    disabled,
    onClick
}) {
    const handleClicked = () => {
        if(!disabled && onClick)
            onClick();
    }

    return (
        <div 
            className={
                Helpers.joinClasses(
                    disabled ? 
                    Style.containerDisabled :
                    Style.container,
                    className
                )
            } 
            onClick={handleClicked}>
            { 
                React.createElement(
                    icon, 
                    {
                        className: Helpers.joinClasses(
                            disabled ? 
                            Style.iconDisabled : 
                            Style.icon,
                            iconClassName
                        )
                    }
                )
            }
        </div>
    )
}

export default IconButton