import React, { useEffect, useState } from 'react'
import EthereumIcon from '~/Assets/Vectors/EthereumIcon';
import MinusIcon from '~/Assets/Vectors/MinusIcon';
import PlusIcon from '~/Assets/Vectors/PlusIcon';
import IconButton from '../IconButton/IconButton';
import Style from './MintCountSelector.module.css';

function MintCountSelector({
    startCount,
    maxCount,
    price,
    onCountChanged
}) {
    const [count, setCount] = useState(startCount || 1);

    const handleIncrement = () => {
        if(count + 1 <= maxCount)
            setCount(count + 1);
    }

    const handleDecrement = () => {
        if(count - 1 >= 1)
            setCount(count - 1);
    }

    useEffect(
        () => {
            if(onCountChanged)
                onCountChanged(count);
        }, 
        [count, onCountChanged]
    )

    useEffect(
        () => {
            if(count > maxCount)
                setCount(maxCount);
        },
        [maxCount, count]
    )

    return (
        <div className={Style.container}>
            <IconButton 
                icon={MinusIcon}
                disabled={count <= 1}
                onClick={handleDecrement}/>

            <p className={Style.countText}>
                { `${count} x ${price}` }
            </p>

            <EthereumIcon className={Style.ethereumIcon}/>

            <IconButton 
                icon={PlusIcon}
                disabled={count >= maxCount}
                onClick={handleIncrement}/>
        </div>
    )
}

export default MintCountSelector