import React from 'react'

function EthereumIcon({
    className,
    onClick
}) {
    return (
        <svg 
            className={className}
            onClick={onClick}
            viewBox="0 0 226.777 226.777" 
            xmlns="http://www.w3.org/2000/svg">
            <g>
                <polygon points="112.553,157 112.553,86.977 44.158,116.937  "/>
                <polygon points="112.553,82.163 112.553,-0.056 46.362,111.156  "/>
                <polygon points="116.962,-0.09 116.962,82.163 184.083,111.566  "/>
                <polygon points="116.962,86.977 116.962,157.002 185.405,116.957  "/>
                <polygon points="112.553,227.406 112.553,171.085 44.618,131.31  "/>
                <polygon points="116.962,227.406 184.897,131.31 116.962,171.085  "/>
            </g>
        </svg>
    )
}

export default EthereumIcon