import { BigNumber, ethers } from "ethers";
import Config from "~/config";
import ABI from "./ABI/NFT.json";

class NFT {
    constructor(web3Interface, signer, signature) {
        this.web3Interface = web3Interface;
        this.signer = signer;
        this.signature = signature || Config.DEFAULT_SIGNATURE;
        this.contract = new ethers.Contract(
            Config.CONTRACT, 
            ABI,
            signer
        );

        this.lastSparseUpdate = 0;
        this.state = null;
    }

    async updateState() {
        let newState = {}

        if(Date.now() > this.lastSparseUpdate + Config.SPARSE_UPDATE_RATE_MS) {
            this.lastSparseUpdate = Date.now();
            
            const isActive = await this.contract.isActive()
            const isPublicSale = await this.contract.isPublicSale()
            const isRevealed = await this.contract.isRevealed()
            const price = await this.contract.price()
            const totalSupply = (await this.contract.totalSupply()).toNumber()
            const maxSupply = (await this.contract.maxSupply()).toNumber()
            const privateMaxMint = (await this.contract.privateMaxMint()).toNumber()
            const maxMintPerTransaction = (await this.contract.maxMintPerTransaction()).toNumber()
    
            newState = {
                isActive,
                isPublicSale,
                isRevealed,
                price,
                totalSupply,
                maxSupply,
                privateMaxMint,
                maxMintPerTransaction,
                signature: this.signature
            }
        }

        if(this.signature) {
            let addressStatus = {
                isValidPermit: false,
                availableMints: BigNumber.from(0)
            }
            
            try {
                addressStatus = await this.contract.getAddressStatus(
                    await this.signer.getAddress(),
                    this.signature
                )
            }
            catch(ex) {}
            
            newState = {
                ...newState,
                isValidPermit: addressStatus.isValidPermit,
                availableMints: addressStatus.availableMints.toNumber()
            }
        }

        if(!this.state)
            this.state = newState;
        else
            this.state = {...this.state, ...newState};

        return this.state;
    }

    async mint(tokenCount) {
        if(!this.state)
            return;

        if(this.state.isPublicSale) {
            return this.contract.publicMint(
                tokenCount,
                { value: this.state.price.mul(tokenCount) }
            );
        }
        else {
            return this.contract.privateMint(
                tokenCount, 
                this.signature,
                { value: this.state.price.mul(tokenCount) }
            );
        }
    }
}

export default NFT;