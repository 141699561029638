import React, { useState } from 'react'
import MinusIcon from '~/Assets/Vectors/MinusIcon';
import PlusIcon from '~/Assets/Vectors/PlusIcon'
import Style from './RoadmapMenuItem.module.css'

function RoadmapMenuItem({
    number,
    title,
    subtitle,
    content
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    
    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    }
    
    return (
        <div className={Style.container}>
            <div className={Style.topBar + (isExpanded ? ' ' + Style.expandedTopBar : '')}>
                <p className={Style.number + (isExpanded ? ' ' + Style.expandedNumber : '')}>{ number }</p>
                <p className={Style.itemTitle}>{ title }</p>
                <div 
                    className={Style.expandIconContainer}
                    onClick={toggleExpanded}>
                    {
                        isExpanded ?
                        <MinusIcon className={Style.expandIcon} /> :
                        <PlusIcon className={Style.expandIcon} />
                    }
                </div>
            </div>

            <div className={Style.contentContainer + (isExpanded ? '' : ' ' + Style.contentHidden)}>
                <p className={Style.subtitle}>{ subtitle }</p>
                <p className={Style.content}>{ content }</p>
            </div>
        </div>
    )
}

export default RoadmapMenuItem