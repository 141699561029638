import React from 'react'
import Helpers from '~/helpers';
import Style from './LabeledIcon.module.css';

function LabeledIcon({
    className,
    iconClassName,
    text,
    icon,
    disabled,
    onClick
}) {
    const handleClicked = () => {
        if(onClick && !disabled)
            onClick();
    }

    return (
        <div 
            className={Helpers.joinClasses(
                disabled ? 
                Style.containerDisabled : 
                Style.container, 
                className
            )} 
            onClick={handleClicked}>

            {
                text &&
                <p className={Style.label}>
                    { text }
                </p>
            }

            {
                icon &&
                <div className={Helpers.joinClasses(Style.iconContainer, iconClassName)}>
                    {
                        React.createElement(
                            icon,
                            { className: Style.icon }
                        )
                    }
                </div>
            }
        </div>
    )
}

export default LabeledIcon