import React from 'react';
import PopupContainer from '../PopupContainer';
import MenuCloseIcon from '~/Assets/Vectors/MenuCloseIcon';
import Button from '~/Components/Button/Button';
import Config from '~/config';
import Style from './TransactionSentPopup.module.css';

const TransactionSentPopup = ({
    hash,
    onClose,
    isOpen,
}) => {
    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={onClose}
            backdropColor="#0009">

            <div className={Style.container}>

                <div className={Style.closeContainer}>     
                    <MenuCloseIcon
                        className={Style.closeIcon}
                        onClick={onClose}/>
                </div>

                <p className={Style.title}>Transaction sent</p>
                <p className={Style.message}>Your transaction has been sent. Please allow some time for the miners to accept your transaction! Once the transaction is accepted, you can view your minted tokens on OpenSea.</p>

                <div className={Style.buttonContainer}>
                    <Button
                        className={Style.button}
                        onClick={() => {
                            window.open(Config.Network.TX_EXPLORER + hash, '_blank')
                            if(onClose)
                                onClose()
                        }}
                        text="View Transaction"/>
                </div>
            </div>

        </PopupContainer>
    );
}

export default TransactionSentPopup;