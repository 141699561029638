import React from 'react'

function MinusIcon({ className }) {
    return (
        <svg 
            className={className}
            viewBox="0 0 32 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="M26 16H6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default MinusIcon