import React from 'react';
import Button from '~/Components/Button/Button';
import PopupContainer from '../PopupContainer';
import Style from './MessagePopup.module.css';

const MessagePopup = ({
    title,
    message,
    onClose,
    isOpen,
}) => {
    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={onClose}
            backdropColor="#0009">

            <div className={Style.container}>

                <p className={Style.title}>{ title }</p>
                <p className={Style.message}>{ message }</p>

                <div className={Style.buttonContainer}>
                    <Button
                        className={Style.button}
                        onClick={onClose}
                        text="Close"/>
                </div>
            </div>

        </PopupContainer>
    );
}

export default MessagePopup;